export const API_URL = process.env.REACT_APP_API_URL;
export const WEB_URL = process.env.REACT_APP_WEB_URL;

import {
  ADMIN,
  LOGIN,
  FORGOT_PASSWORD,
  OTP_VERIFY,
  RESET_PASSWORD,
  SET_PASSWORD,
  DASHBOARD,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  CMS,
  EMAIL_TEMPLATE,
  TRANSLATIONS,
  FAQ,
  FAQ_CATEGORY,
  USERS,
  NOTIFICATIONS,
  SETTING,
  FEATURE,
  TEAM_MEMBER,
  ADD,
  EDIT,
  LIST,
  VIEW,
  SETTING_HOME,
  SETTING_ABOUT,
  SETTING_CONTACT_US,
  HOME_BANNER,
  HOME_JOB_POST,
  HOME_DOWNLOAD,
  HOME_FOOTER,
  HOME_SEO,
  ABOUT_BANNER,
  ABOUT_WHO_WE_ARE,
  ABOUT_MISSION,
  ABOUT_VISION,
  ABOUT_OUR_TEAM,
  ABOUT_SEO,
  GENERAL,
  CONTACT_SETTING,
  FAQ_SETTING,
  SETTING_FAQ,
  MAIL_SETTING,
  HOME,
  ABOUT,
  CONTACT,
  CATEGORIES,
  ATTRIBUTES,
  BUSINESS_TYPE,
  STATE_CITY,
  BOOSTS,
  PRICING,
} from './routes';
import {
  commonOption,
  userAccessOptions,
  userStatusOption,
  userSignupTypeOptions,
  adminStatusOption,
  categoryTypeOption,
  attributesTypeOption,
} from './option';
import {
  aboutNavItems,
  contactNavItems,
  faqNavItems,
  homeNavItems,
  mailNotificationNavItems,
  accountNavItems,
} from './navConfig';

export {
  ADMIN,
  LOGIN,
  FORGOT_PASSWORD,
  OTP_VERIFY,
  RESET_PASSWORD,
  SET_PASSWORD,
  DASHBOARD,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  CMS,
  EMAIL_TEMPLATE,
  TRANSLATIONS,
  FAQ,
  FAQ_CATEGORY,
  USERS,
  NOTIFICATIONS,
  SETTING,
  FEATURE,
  TEAM_MEMBER,
  ADD,
  EDIT,
  VIEW,
  LIST,
  SETTING_HOME,
  SETTING_ABOUT,
  SETTING_CONTACT_US,
  HOME_BANNER,
  HOME_JOB_POST,
  HOME_DOWNLOAD,
  HOME_FOOTER,
  HOME_SEO,
  ABOUT_BANNER,
  ABOUT_WHO_WE_ARE,
  ABOUT_MISSION,
  ABOUT_VISION,
  ABOUT_OUR_TEAM,
  ABOUT_SEO,
  GENERAL,
  CONTACT_SETTING,
  FAQ_SETTING,
  SETTING_FAQ,
  MAIL_SETTING,
  HOME,
  ABOUT,
  CONTACT,
  CATEGORIES,
  ATTRIBUTES,
  aboutNavItems,
  contactNavItems,
  faqNavItems,
  homeNavItems,
  mailNotificationNavItems,
  accountNavItems,
  userAccessOptions,
  userStatusOption,
  userSignupTypeOptions,
  adminStatusOption,
  commonOption,
  categoryTypeOption,
  attributesTypeOption,
  BUSINESS_TYPE,
  STATE_CITY,
  BOOSTS,
  PRICING,
};
