import client from 'libs/HttpClient';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useDispatch } from 'react-redux';
import { storeToken } from 'store';

const firebaseConfig = {
  apiKey: 'AIzaSyBel0EnyizPNRWZFly_CN1pmpKX0SUrkJU',
  authDomain: 'collegely-c373f.firebaseapp.com',
  projectId: 'collegely-c373f',
  storageBucket: 'collegely-c373f.appspot.com',
  messagingSenderId: '142384815598',
  appId: '1:142384815598:web:62366f84f6ce292df202ff',
  measurementId: 'G-MQ98DY1E5Y',
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

// Function to request permission and get FCM token
export const requestForToken = async (dispatch) => {
  if ('Notification' in window) {
    const hasAlertedUser = localStorage.getItem('hasAlertedUser');
    const permission = await Notification.requestPermission();

    if (permission === 'denied' && !hasAlertedUser) {
      // alert('Please allow notifications');
      localStorage.setItem('hasAlertedUser', 'true');
    } else if (permission === 'granted') {
      generateToken(dispatch);
    }
  }
};

// Function to generate and store the token
const generateToken = async (dispatch) => {
  try {
    const token = await getToken(messaging, {
      vapidKey:
        'BHlOyarGRplUYjGvKL2zSSGxp5KaXSjzKXkxmwkR1kbuKKE4JpmWNS6pQ3DN6zbp6_h7-mwFIAlR5L4RSKVtc1g',
    });

    if (token) {
      dispatch(storeToken(token)); // Dispatch the action with the generated token
      await client.post('/notifications/store', { token });
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (error) {
    console.error('Error generating FCM token:', error);
  }
};

// Listen for incoming messages
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
