import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, NavDropdown, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import 'assets/scss/section/_header.scss';
import profile_image_icon from 'assets/images/Profile.jpg';
import { logoutSuccess, loggedUser } from 'store';
import { LogoUrlFront } from 'common';
import { LOGIN, EDIT_PROFILE, NOTIFICATIONS } from 'config';

const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationCount = useSelector((state) => state?.notifications?.count);
  const [isNotificationListVisible, setIsNotificationListVisible] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const handleNavbarToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
    if (isNavbarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };
  const handleEditProfile = () => {
    navigate(EDIT_PROFILE);
  };

  const handleLogout = () => {
    dispatch(logoutSuccess());
    navigate(LOGIN);
  };

  const checkLoggedInUser = useSelector(loggedUser);

  const handleBellClick = () => {
    navigate(NOTIFICATIONS);
    setIsNotificationListVisible(!isNotificationListVisible);
  };

  const UserMenu = (
    <svg width="9" height="21" viewBox="0 0 9 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="4.44691" cy="4.49619" rx="1.44691" ry="1.49619" fill="#9592A6" />
      <ellipse cx="4.44691" cy="10.4886" rx="1.44691" ry="1.49619" fill="#9592A6" />
      <ellipse cx="4.44691" cy="16.4809" rx="1.44691" ry="1.49619" fill="#9592A6" />
    </svg>
  );

  return (
    <>
      <Navbar className="sticky-top navbar-section">
        <Container fluid className="container-section">
          <Navbar.Brand className="dashboard-logo">
            <LogoUrlFront />
          </Navbar.Brand>

          <div className="header-setting-menu">
            <div className="dashboard-toggle-btn">
              <FontAwesomeIcon
                icon={faBars}
                onClick={() => {
                  props.toggleClass();
                  handleNavbarToggle();
                }}
              />
            </div>
            <div className="desktop-login-name">
              <div
                className="notification-icon"
                onClick={handleBellClick}
                style={{ position: 'relative' }}>
                <FontAwesomeIcon icon={faBell} className="me-2" />
                {<span className="notification-count"></span>}
                {notificationCount > 0 && (
                  <span
                    className="notification-count"
                    style={{ position: 'absolute', top: 0, right: 0 }}>
                    {notificationCount}
                  </span>
                )}
              </div>
              <span>{checkLoggedInUser.user.full_name}</span>
            </div>
            <div className="profile-img">
              <img
                src={
                  checkLoggedInUser.user.profile_image.split('/').pop() !== 'default.jpeg'
                    ? checkLoggedInUser.user.profile_image
                    : profile_image_icon
                }
                width="40px"
                height="40px"
                alt="profile_img"
              />
            </div>
            <NavDropdown title={UserMenu}>
              <div className="mobile-login-name">
                <span>{checkLoggedInUser.user.full_name}</span>
                <NavDropdown.Divider className="separator" />
              </div>
              <NavDropdown.Item className="setting-items" onClick={handleEditProfile}>
                {props.t('page.header_edit_profile')}
              </NavDropdown.Item>
              <NavDropdown.Item className="setting-items" onClick={handleLogout}>
                {props.t('page.header_logout')}
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

Header.propTypes = {
  toggleClass: PropTypes.any.isRequired,
  t: PropTypes.func,
};

export { Header };
